<template>
  <div class="form-wrapper">
    <div>
      <div>
        <h3 class="font-weight-bolder mb-1">
          {{ $t(ORDER_ITEMS_FORM_TITLE) }}
        </h3>
      </div>
      <rental-table />
      <sales-table />
    </div>
    <div>
      <other-charges-table />
    </div>
  </div>
</template>

<script>

import SalesTable from '@/views/main/catalog/view/components/SalesTable.vue'
import RentalTable from '../../../../../../catalog/view/components/RentalTable.vue'
import OtherChargesTable from './OtherChargesTable.vue'
import config from '../config'

export default {
  name: 'OrderItems',
  components: {
    SalesTable,
    RentalTable,
    OtherChargesTable,
  },
  data() {
    return {
      routeQuery: this.$route.query,
    }
  },
  computed: {
    orderItemsRental() {
      return this.$store.state[this.MODULE_NAME].orderItemsRentalTable
    },
    orderItemsSales() {
      return this.$store.state[this.MODULE_NAME].orderItemsSalesTable
    },
    orderInformation() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm
    },
    isDispatchAndReturnAllRequiredFieldsFilled() {
      return this.$store.state[this.MODULE_NAME].isDispatchAndReturnAllRequiredFieldsFilled
    },
    isEventInformationRequiredFieldsFilled() {
      return this.$store.state[this.MODULE_NAME].isEventInformationRequiredFieldsFilled
    },
  },
  mounted() {
    if (this.routeQuery) {
      if (this.stringToBoolean(this.routeQuery.is_rental)) {
        this.$store.dispatch(`${this.MODULE_NAME}/rentalOrders`, {
          ids: [this.routeQuery.product_id],
        }).then(({ data }) => {
          const product = { ...data.data.data[0], amount: 1 }
          if (this.orderItemsRental) {
            const products = this.orderItemsRental.forEach(item => {
              if (item.id === product.product_id) {
                // eslint-disable-next-line no-param-reassign
                item.amount += 1
              }
            })
            this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, [products])
            return
          }
          this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, [product])
        })
      }
      if (this.stringToBoolean(this.routeQuery.is_sale)) {
        this.$store.dispatch(`${this.MODULE_NAME}/saleOrders`, {
          ids: [this.routeQuery.product_id],
        }).then(({ data }) => {
          const product = { ...data.data.data[0], amount: 1 }
          if (this.orderItemsSales) {
            const products = this.orderItemsSales.forEach(item => {
              if (item.id === product.product_id) {
                // eslint-disable-next-line no-param-reassign
                item.amount += 1
              }
            })
            this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALES_TABLE`, [products])
            return
          }
          this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALES_TABLE`, [product])
        })
      }
    }
  },
  methods: {
    stringToBoolean(value) {
      if (typeof value === 'string') {
        switch (value.toLowerCase().trim()) {
          case 'true':
          case '1':
            return true
          case 'false':
          case '0':
          case null:
          case undefined:
            return false
          default:
            return Boolean(value)
        }
      }
      if (typeof value === 'boolean') return value
      return Boolean(value) // Handles non-string values
    },
  },
  setup() {
    const { ORDER_ITEMS_FORM_TITLE, MODULE_NAME } = config()

    return {
      ORDER_ITEMS_FORM_TITLE,
      MODULE_NAME,
    }
  },
}
</script>
<style lang="scss">
.l-tableList-collector .table-striped tbody tr:last-child:not(.b-table-empty-row) td:last-child{
  border-bottom-right-radius: 5px !important;
}
.l-tableList-collector .table-striped tbody tr:last-child:not(.b-table-empty-row) td:first-child{
  border-bottom-left-radius: 5px !important;
}
.l-tableList-collector .table-striped tbody tr:last-child:not(.b-table-empty-row) td{
  border-bottom: solid 1px #dde3e7 !important;
}
</style>
