<template>
  <div class="form-wrapper">
    <div>
      <h3 class="font-weight-bolder mb-1">
        {{ $t(CUSTOMER_INFORMATION_FORM_TITLE) }}
      </h3>
    </div>
    <b-row>
      <b-col md="6">
        <component
          :is="customerInformationFields['billing_contact_id'].type"
          ref="billing_contact_ref"
          v-model="customerInformation['billing_contact_id']"
          v-bind="
            getProps(
              'billing_contact_id',
              customerInformationFields,
              true,
              'billing_contact_id',
              getParentValue
            )
          "
          @input="onChangeBillingContact($event)"
        />
        <component
          :is="customerInformationFields['billing_phone'].type"
          ref="billing_phone_ref"
          v-model="customerInformation['billing_phone']"
          :is-editable="true"
          v-bind="getProps('billing_phone', customerInformationFields)"
        />
        <component
          :is="customerInformationFields[field].type"
          v-for="field in ['po_number']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="customerInformation[field]"
          v-bind="getProps(field, customerInformationFields)"
          :is-required-field="requiresPurchaseOrderReq"
        />
        <span
          class="font-weight-bolder d-inline-block"
          style="margin-bottom: 8px; padding-left: 8px"
        >
          {{ $t("Payer Account") }}
        </span>

        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-for="radio in payerAccount"
            :key="radio.value"
            v-model="payerAccountType['value']"
            class="d-flex mb-1"
            :aria-describedby="ariaDescribedby"
            :name="radio.value"
            :value="radio.value"
            @change="onPayerAccountChange"
          >
            {{ $t(radio.title) }}
          </b-form-radio>
        </b-form-group>

        <Transition name="fade">
          <div
            v-if="idPayerAccountThirdParty"
            class="mb-1"
          >
            <div class="mb-1">
              <select-account
                class="mb-1"
                :auto-suggest-table-columns="autoSuggestTableColumnsAccount"
                :module-name="MODULE_NAME"
                :module-name-orders="MODULE_NAME_ORDERS"
                label="Payer Account"
                @selectHandler="selectHandler"
              />

              <div
                class="d-flex"
                style="gap: 8px"
              >
                <feather-icon
                  class="position-relative"
                  style="top: 3px"
                  icon="LWarningIconBlue"
                  size="16"
                />
                <span>
                  {{ PAYER_ACCOUNT_THIRD_PART_CONFIRMATION_TEXT }}
                </span>
              </div>
            </div>
            <component
              :is="customerInformationFields[field].type"
              v-for="field in ['payer_email']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="customerInformation[field]"
              v-bind="getProps(field, customerInformationFields)"
            />
          </div>
        </Transition>

        <component
          :is="customerInformationFields[field].type"
          v-for="field in [ 'payment_term_id']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="customerInformation[field]"
          v-bind="getProps(field, customerInformationFields)"
        />

        <component
          :is="customerInformationFields[field].type"
          v-for="field in ['customer_discount']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="customerInformation[field]"
          v-bind="getProps(field, customerInformationFields)"
        />

        <upload-attachment />
      </b-col>
      <b-col md="6">
        <component
          :is="customerInformationFields[field].type"
          v-for="field in ['billing_email', 'billing_address']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="customerInformation[field]"

          :is-editable="true"
          class="h-auto"
          v-bind="getProps(field, customerInformationFields)"
        />
        <span class="font-weight-bolder order-rules-title">{{
          $t("Order Rules")
        }}</span>
        <component
          :is="customerInformationFields['order_rules_required_security_deposit'].type"
          ref="order_rules_required_security_deposit_ref"
          v-model="customerInformation['order_rules_required_security_deposit']"
          v-bind="getProps('order_rules_required_security_deposit', customerInformationFields)"
          @change="handleOrderRulesChange"
        />
        <component
          :is="customerInformationFields[field].type"
          v-for="field in ['price_tier', 'customer_notes']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="customerInformation[field]"
          :is-editable="true"
          class="h-auto"
          v-bind="getProps(field, customerInformationFields)"
        />
        <div
          class="d-flex align-items-center"
          style="gap: 8px;"
        >
          <component
            :is="customerInformationFields[field].type"
            v-for="field in ['order_placed_by_id']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="customerInformation[field]"
            class="w-100"
            v-bind="getProps(field, customerInformationFields, true, field, getParentValue)"
          />
          <button-dashed
            class="btnBorderRevert position-relative"
            style="top: 6px"
          >
            <feather-icon
              icon="LAddButtonIcon"
              class="cursor-pointer"
              size="32"
              @click="gotoNewPage(`/settings/users/create`, $event, '_blank')"
            />
          </button-dashed>

          <!-- <OrderPlacedByModal ref="modalOrderPlacedBy" @ok="handleModalOk" /> -->
        </div>
        <component
          :is="customerInformationFields[field].type"
          v-for="field in ['receive_method']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="customerInformation[field]"
          class="w-100"
          v-bind="getProps(field, customerInformationFields)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormRadio,
} from 'bootstrap-vue'
import { getProps } from '@core/utils/utils'
import SelectAccount from '@/views/main/orders/components/use-as-global/SelectAccount.vue'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import config from '../config'
import UploadAttachment from './UploadAttachment.vue'

export default {
  name: 'CustomerInformation',
  components: {
    ButtonDashed,
    SelectAccount,
    BFormRadio,
    BFormGroup,
    BRow,
    BCol,
    UploadAttachment,
  },
  computed: {
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    payerAccountType() {
      return this.$store.state[this.MODULE_NAME].payerAccount
    },
    idPayerAccountThirdParty() {
      return this.payerAccountType.value === this.PAYER_ACCOUNT_THIRD_PARTY
    },
    requiresPurchaseOrderReq() {
      return this.$store.state[this.MODULE_NAME].requiresPurchaseOrder
    },
    orderItemsOtherCharges() {
      return this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable
    },
    depositRequirement() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm?.deposit_requirement
    },
  },
  watch: {
    depositRequirement(val) {
      if (val) {
        this.handleOrderRulesChange(this.customerInformation.order_rules_required_security_deposit)
      }
    },
  },
  methods: {
    onPayerAccountChange() {
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformation,
        payer_email: '',
      })
    },
    handleOrderRulesChange(value) {
      const price = this.depositRequirement
      const defaultValueForOtherCharges = {
        name: 'Deposit Requirement',
        amount: 1,
        price,
        discount: 0,
        tax: 0,
        subtotal: price,
        totalPrice: price,
      }

      const hasIds = this.orderItemsOtherCharges?.filter(i => i.id)

      if (value && Boolean(price)) {
        this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, [defaultValueForOtherCharges, ...hasIds])
      } else {
        this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, hasIds)
      }
    },
    getParentValue() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm?.customer_id
    },
    onChangeBillingContact(item) {
      const cellNoObj = item?.phone_one_object

      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformation,
        billing_phone: cellNoObj ? cellNoObj?.formatInternational : item?.phone_one,
        billing_email: item?.email,
      })
    },
    selectHandler(item) {
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformation,
        ...{ payer_account_id: item },
      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
      payerAccount,
      MODULE_NAME_ORDERS,
      MODULE_NAME_ATTACHMENT,
      customerInformationFields,
      PAYER_ACCOUNT_THIRD_PARTY,
      CUSTOMER_INFORMATION_FORM_TITLE,
      autoSuggestTableColumnsAccount,
      PAYER_ACCOUNT_THIRD_PART_CONFIRMATION_TEXT,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      payerAccount,
      MODULE_NAME_ORDERS,
      MODULE_NAME_ATTACHMENT,
      customerInformationFields,
      PAYER_ACCOUNT_THIRD_PARTY,
      autoSuggestTableColumnsAccount,
      CUSTOMER_INFORMATION_FORM_TITLE,
      PAYER_ACCOUNT_THIRD_PART_CONFIRMATION_TEXT,
    }
  },
}
</script>
<style lang="scss">
.billing_address-border-danger {
  & textarea,
  & label {
    border-color: #6f1214;
    color: #6f1214;
  }

  & label {
    font-weight: bold;
  }
}
.order-rules-title {
  padding-left: 10px;
}
</style>
