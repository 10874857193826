<template>
  <div>
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div>
        <b-button
            v-if="true"
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="$router.push({name: 'home-orders-rental-sales-list'})"
        >
          {{ $t('Back to List') }}
        </b-button>
        <b-button
            v-else
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
        >
          <feather-icon
              icon="LCancelIcon"
              size="16"
          />
          {{ $t('Cancel') }}
        </b-button>
      </div>
      <div
          class="d-flex"
          style="gap: 8px"
      >
        <b-button
            variant="secondary"
            class="font-medium-1 font-weight-bold px-3"
            @click="printCanvas"
        >
          <feather-icon icon="LPrintIcon" />
          {{ $t('Print') }}
        </b-button>

        <b-overlay
            :show="loading === DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
        >
          <b-button
              :disabled="getDisabledButton(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT)"
              variant="secondary"
              class="font-medium-1 font-weight-bold px-3"
              @click="onSubmit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT)"
          >
            {{ $t(FORM_BUTTON_TITLE_SAVE_AS_DRAFT) }}
          </b-button>
        </b-overlay>
        <b-overlay
            :show="loading === DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
        >
          <b-button
              :disabled="getDisabledButton(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS)"
              variant="danger"
              class="font-medium-1 font-weight-bold px-3"
              @click="onSubmit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS)"
          >
            {{ $t('Hold Assets') }}
          </b-button>
        </b-overlay>
        <b-overlay
            :show="loading === DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
        >
          <b-button
              variant="secondary"
              class="font-medium-1 font-weight-bold px-3"
              :disabled="getDisabledButton(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL)"
              @click="onSubmit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL)"
          >
            {{ $t('Send by Email') }}
          </b-button>
        </b-overlay>
        <b-overlay
            :show="loading === DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
        >
          <b-button
              variant="success"
              class="font-medium-1 font-weight-bold px-3"
              :disabled="getDisabledButton(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER)"
              @click="onSubmit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER)"
          >
            {{ $t('Place Order') }}
          </b-button>
        </b-overlay>

      </div>
      <!--        <b-button-->
      <!--          variant="success"-->
      <!--          class="font-medium-1 font-weight-bold px-3"-->
      <!--          @click="gotoNewPage(`/orders/rental-sales/payment/${order.id}`, $event)"-->
      <!--        >-->
      <!--          <feather-icon icon="LCreditCardIconFill" />-->
      <!--          {{ $t('Charge') }}-->
      <!--        </b-button>-->
    </div>
  </div>
</template>

<script>

import {BButton, BOverlay} from 'bootstrap-vue'
import {mapGetters} from 'vuex'
import {format} from 'date-fns'
import html2canvas from 'html2canvas'
import config from '@/views/main/orders/view/rental-sales/config'
import jsonToFormData from '@/libs/jsonToFormData'
import {validate} from 'vee-validate'


export default {
  name: 'FormBottom',
  components: {
    BButton,
    BOverlay,
  },
  data() {
    return {
      loading: '',
    }
  },
  props: {
    formRef: {
      type: Object,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('rental-sales', ['getTotalDataOfForms']),
    isFormChangedAsPlaceOrder() {
      return Boolean(Object.keys(this.orderInformation)?.length && Object.keys(this.customerInformation)?.length && Object.keys(this.dispatchAndReturn)?.length && Object.keys(this.eventInformation)?.length)
    },
    eventInformation() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },
    dispatchAndReturn() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm
    },
    isEventInformationRequiredFieldsFilled() {
      return this.$store.state[this.MODULE_NAME].isEventInformationRequiredFieldsFilled
    },
    rentalSalesFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].rentalSalesFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.getTotalDataOfForms) !== this.rentalSalesFormClone
    },
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    orderInformation() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm
    },
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    isApprove() {
      return this.$route.fullPath?.includes('approve')
    },
  },
  methods: {
    getDisabledButton(action) {
      if (!this.isUpdate) {
        return action !== this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT
      }
      return false;
    },

    redirectToRFQSend(id, action) {
      const routeNames = {
        [this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT]: 'draft-quote-information',
        [this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER]: 'approve-quote-information',
        [this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS]: 'hold-quote-information',
      };

      if (id && routeNames[action]) {
        this.$router.push({
          name: routeNames[action],
          params: { id },
        });
      } else {
        this.$router.push({
          name: 'home-orders-rental-sales',
        });
      }
    },
    onSubmit(action) {
      switch (action){
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT:
            if (this.isUpdate){this.updateDraft()}else{this.draftSubmit()}
          break;
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS:
          this.holdSubmit()
          break;
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER:
          this.placeOrderSubmit()
          break;
        default:
          this.$toast.error('Unsupported action')

      }

      // this.getFormData()
      // const url = `${this.MODULE_NAME}/${id ? 'update_draft' : 'create_draft'}`
      // this.loading = action
      // this.sendNotification(this, payload, url, id)
      //   .then(async ({data}) => {
      //     const { id } = data.data
      //     if (action !== this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT) {
      //      await this.sendNotification(this, { id, status: action }, `${this.MODULE_NAME}/updateStatusOfItem`, id);
      //     }
      //
      //     this.redirectToRFQSend(id, action);
      //     this.onClear();
      //   })
      //   .catch((err) => {
      //     this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data);
      //     this.errorNotification(this, err)
      //   })
      //   .finally(() => {
      //     this.loading = '';
      //   })
    },

    getFormData() {
      const totalDataOfForms = this.getTotalDataOfForms
      const payload = {
        customer_id: totalDataOfForms.customer_id,
        // bill_email: totalDataOfForms.billing_email,
        // bill_phone: totalDataOfForms.billing_phone,
        // bill_address: totalDataOfForms.billing_address,
        // price_tier: totalDataOfForms.price_tier,
        // customer_notes: totalDataOfForms.customer_notes,
        billing_contact_id: totalDataOfForms.billing_contact_id?.id,
        po_number: totalDataOfForms.po_number,
        payer_account: totalDataOfForms.payer_account,
        payer_account_id: totalDataOfForms.payer_account_id?.id,
        payer_email: totalDataOfForms.payer_email,
        payment_term_id: totalDataOfForms.payment_term_id?.id,
        customer_discount: totalDataOfForms.customer_discount,
        order_rules_required_security_deposit: totalDataOfForms.order_rules_required_security_deposit  ? 1 : 0,
        order_placed_by_id: totalDataOfForms.order_placed_by_id?.id,
        receive_method: totalDataOfForms.receive_method?.id,
        dispatch_warehouse_id: totalDataOfForms.dispatch_warehouse_id?.id,
        dispatch_method: totalDataOfForms.dispatch_method?.id,
        return_method: totalDataOfForms.return_method?.id,
        order_routing_order_returns_to_another_warehouse: totalDataOfForms.order_routing_order_returns_to_another_warehouse  ? 1 : 0,
        order_routing_allow_dispatch_of_items_from_warehouse_of_origin: totalDataOfForms.order_routing_allow_dispatch_of_items_from_warehouse_of_origin ? 1 : 0,
        return_warehouse_id: totalDataOfForms.return_warehouse_id?.id,
        internal_shipping_notes: totalDataOfForms.internal_shipping_notes,
        return_contact_is_different: totalDataOfForms.return_contact_is_different ? 1 : 0,
        dispatch_contact_id: totalDataOfForms.dispatch_contact_id?.id,
        return_contact_id: totalDataOfForms.return_contact_id?.id,
        dispatch_address_id: totalDataOfForms.dispatch_address_id?.id,
        return_address_id: totalDataOfForms.return_address_id?.id,
        dispatch_phone: totalDataOfForms.dispatch_phone,
        dispatch_email: totalDataOfForms.dispatch_email,
        event_information_dispatch_date: totalDataOfForms?.event_information_dispatch_date && format(totalDataOfForms?.event_information_dispatch_date, 'yyyy-MM-dd'),
        event_information_rental_start_and_dispatch_time: totalDataOfForms?.event_information_rental_start_dispatch && `${format(totalDataOfForms?.event_information_rental_start_dispatch, 'yyyy-MM-dd')} ${totalDataOfForms?.event_information_rental_start_dispatch_time?.substring(0, 5)}`,
        event_information_rental_end_and_receive_time: totalDataOfForms?.event_information_rental_end && `${format(totalDataOfForms?.event_information_rental_end, 'yyyy-MM-dd')} ${totalDataOfForms?.event_information_rental_end_time?.substring(0, 5)}`,
        event_reference: totalDataOfForms.event_reference,
        total_order_notes: totalDataOfForms.total_order_notes,

        products: totalDataOfForms.products?.map(product => ({
          product_id: product?.product_id,
          amount: product?.amount,
          type: product.type,
          notes: product?.notes,
          rental_start: (product.type === 'rent' && product?.rental_start && product?.event_information_rental_start_time) ? `${format(product?.rental_start, 'yyyy-MM-dd')} ${product?.event_information_rental_start_time?.substring(0, 5)}` : null,
          rental_end: (product.type === 'rent' && (product?.rental_end && product?.event_information_rental_end_time)) ? `${format(product?.rental_end, 'yyyy-MM-dd')} ${product?.event_information_rental_end_time?.substring(0, 5)}` : null,
        })),
        other_charges: totalDataOfForms?.other_charges?.filter(i => i?.id)?.map(i => ({
          unit: i.amount,
          product_id: this.isUpdate ? i.product_id : i.id,
        })),
      }
      return this.isUpdate ? {...payload, ...{id: this.$route.params.id, _method: 'patch'}} : payload
    },

    onClear() {
      for (let i = 0; i < this.storeMutationsList.length; i++) {
        this.$store.commit(`${this.MODULE_NAME}/${this.storeMutationsList[i].mutation}`, this.storeMutationsList[i].payload)
      }
    },
    printCanvas() {
      const appContentEl = document.getElementById('app-content')

      html2canvas(appContentEl).then(canvas => {
        const printWindow = window.open('', '_blank')
        const printDocument = printWindow.document

        printDocument.write(`
        <html>
          <head>
            <title>Print Canvas</title>
            <style>
              body { margin: 0;height: auto; }
              canvas { height: auto; width: 100%;}
            </style>
          </head>
          <body>
            <canvas id="printCanvas" style="display:block;"></canvas>
          </body>
        </html>
      `)

        const printCanvas = printDocument.getElementById('printCanvas')
        printCanvas.width = canvas.width
        printCanvas.height = canvas.height

        const printCtx = printCanvas.getContext('2d')
        printCtx.drawImage(canvas, 0, 0)

        printWindow.print()
        printWindow.close()
      })
    },
    draftSubmit() {
      const payload = this.getFormData();
      validate(payload.customer_id, 'required|integer', {
        name: 'supplier-name',
      }).then(result => {
        if (!result.valid) {
          this.formRef.createForm.$refs.orderInformation.$refs.selectAccount.$refs.validationProvider.setErrors(['The Account field is required'])
          const element = document.getElementById('create_page_title');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            element.focus()
          }
        }else {
          if (payload.products.length <= 0) {
            this.toastErrorNotification(this, 'Please add product')
          }else {
            this.sendNotification(this, jsonToFormData(payload), `${this.MODULE_NAME}/createDraft`)
                .then(response => {
                  const {data} = response.data
                  this.$router.push({
                    name: 'draft-quote-information',
                    params: { id: data.id },
                  })
                  // this.$router.push();
                })
                .catch(err => {
                  this.errorNotification(this, err)
                })
                .finally(() => {})
          }
        }
      });
    },
    updateDraft() {
      const payload = this.getFormData();
      this.sendNotification(this, jsonToFormData(payload), `${this.MODULE_NAME}/updateDraft`)
          .then(response => {
            const {data} = response.data
            this.$router.push({
              name: 'draft-quote-information',
              params: { id: data.id },
            })
            // this.$router.push();
          })
          .catch(err => {
            this.errorNotification(this, err)
          })
          .finally(() => {})
    },
    holdSubmit() {

      const payload = this.getFormData();
      this.sendNotification(this, jsonToFormData(payload), `${this.MODULE_NAME}/updateDraft`)
          .then(response => {
            this.formRef.salesRentalQuoteForm.validate().then(success => {
              if (success) {
                this.sendNotification(this, payload, `${this.MODULE_NAME}/hold`)
                    .then(response => {
                      const {data} = response.data
                      this.$router.push({
                        name: 'draft-quote-information',
                        params: { id: data.id },
                      })
                      // this.$router.push();
                    })
                    .catch(err => {
                      this.errorNotification(this, err)
                    })
                    .finally(() => {})
              }})
          })
          .catch(err => {
            this.errorNotification(this, err)
          })
          .finally(() => {})

    },
    placeOrderSubmit() {
      console.log('placeOrderSubmit');
    },
  },
  setup() {
    const {
      getProps,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
      commonFormButtons,
      approveStatusButtons,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
      commonFormButtons,
      approveStatusButtons,
    }
  },
}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0
}
</style>
