<template>
  <div>
    <order-information
      ref="orderInformation"
      :customer-id="customerID"
      class="mb-1"
    />

    <form-piece-appear-on-customer-select />

    <event-information
      class="mb-1"
    />

    <order-items class="mb-1" />

    <dispatch-and-return class="mb-1" />

    <totals class="mb-1" />

  </div>
</template>

<script>

import { BForm } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import config from '@/views/main/orders/view/rental-sales/config'
import DispatchAndReturn from '@/views/main/orders/view/rental-sales/components/create/components/DispatchAndReturn.vue'
import Totals from './components/Totals.vue'
import OrderItems from './components/OrderItems.vue'
import EventInformation from './components/EventInformation.vue'
import OrderInformation from './components/OrderInformation.vue'
import FormPieceAppearOnCustomerSelect from './FormPieceAppearOnCustomerSelect.vue'

export default {
  name: 'Form',
  components: {
    DispatchAndReturn,
    // BForm,
    Totals,
    OrderItems,
    // ErrorAlert,
    OrderInformation,
    EventInformation,
    // ValidationObserver,
    FormPieceAppearOnCustomerSelect,
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customerID: this.$route.query?.id,
    }
  },
  computed: {
    // onError() {
    //   return this.$store.state[this.MODULE_NAME].onError
    // },
  },
  created() {
    this.$store.dispatch('listModule/warehousesAddress')
    this.onClear()
  },
  methods: {
    onClear() {
      for (let i = 0; i < this.storeMutationsList.length; i++) {
        this.$store.commit(`${this.MODULE_NAME}/${this.storeMutationsList[i].mutation}`, this.storeMutationsList[i].payload)
      }
    },
  },
  setup() {
    const {
      MODULE_NAME,
      quoteStatusBar,
      storeMutationsList,
    } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      storeMutationsList,
    }
  },
}
</script>
