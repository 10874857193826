<template>
  <div class="form-wrapper">
    <div>
      <h3 class="font-weight-bolder mb-1">
        {{ $t(DISPATCH_FORM_TITLE) }}
        <!--        {{ isRental ? $t(' & ') + $t(RETURN_FORM_TITLE) : '' }}-->
      </h3>
    </div>
    <b-row>
      <b-col md="6">
        <component
          :is="dispatchAndReturnFields['dispatch_warehouse_id'].type"
          ref="dispatch_warehouse_id_ref"
          v-model="dispatchAndReturn['dispatch_warehouse_id']"
          v-bind="getProps('dispatch_warehouse_id', dispatchAndReturnFields)"
          @input="onDispatchMethodChange($event, 'dispatch_warehouse_id')"
        />
        <component
          :is="dispatchAndReturnFields['dispatch_method'].type"
          ref="dispatch_method_ref"
          v-model="dispatchAndReturn['dispatch_method']"
          v-bind="getProps('dispatch_method', dispatchAndReturnFields)"
          @input="onDispatchMethodChange($event, 'dispatch_method')"
        />
        <Transition name="fade">
          <div
            v-if="isOrderReturnsToAnotherWarehouse"
          >
            <component
              :is="dispatchAndReturnFields[field].type"
              v-for="field in ['return_warehouse_id']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="dispatchAndReturn[field]"
              :value="dispatchAndReturn[field]"
              v-bind="getProps(field, dispatchAndReturnFields)"
              class="h-auto"
              :disabled-option-by-id="currentWarehouse.id"
            />
          </div>
        </Transition>
        <span class="font-weight-bolder order-routing-title">{{ $t('Order Routing') }}</span>
        <component
          :is="dispatchAndReturnFields['order_routing_order_returns_to_another_warehouse'].type"
          key="order_routing_order_returns_to_another_warehouse"
          ref="order_routing_order_returns_to_another_warehouse_ref"
          v-model="dispatchAndReturn['order_routing_order_returns_to_another_warehouse']"
          class="mb-0"
          v-bind="getProps('order_routing_order_returns_to_another_warehouse', dispatchAndReturnFields)"
        />
        <component
          :is="dispatchAndReturnFields[field].type"
          v-for="field in ['order_routing_allow_dispatch_of_items_from_warehouse_of_origin']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="dispatchAndReturn[field]"
          class="mb-0"
          v-bind="getProps(field, dispatchAndReturnFields)"
        />
        <component
          :is="dispatchAndReturnFields['return_method'].type"
          :ref="`return_method_ref`"
          v-model="dispatchAndReturn['return_method']"
          v-bind="getProps('return_method', dispatchAndReturnFields)"
        />
        <component
          :is="dispatchAndReturnFields[field].type"
          v-for="field in ['internal_shipping_notes']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="dispatchAndReturn[field]"
          v-bind="getProps(field, dispatchAndReturnFields)"
          class="h-auto"
        />
      </b-col>
      <b-col md="6">
        <component
          :is="dispatchAndReturnFields['return_contact_is_different'].type"
          ref="return_contact_is_different_ref"
          v-model="dispatchAndReturn['return_contact_is_different']"
          v-bind="getProps('return_contact_is_different', dispatchAndReturnFields)"
        />

        <div
          class="d-flex align-items-center"
          style="gap: 8px"
        >
          <component
            :is="dispatchAndReturnFields['dispatch_contact_id'].type"
            ref="dispatch_contact_id_ref"
            v-model="dispatchAndReturn['dispatch_contact_id']"
            class="w-100"
            v-bind="getProps('dispatch_contact_id', dispatchAndReturnFields, true, 'dispatch_contact_id', getCustomerId)"
            @input="onDispatchReturnContactChange($event, 'dispatch_contact_id')"
          />
          <button-dashed
            class="btnBorderRevert position-relative"
            style="top: 6px"
          >
            <feather-icon
              icon="LAddButtonIcon"
              class="cursor-pointer"
              size="32"
              @click="gotoNewPage(`/customers/${orderInformationFormCustomerID}/contacts`, $event, '_blank')"
            />
          </button-dashed>
        </div>
        <component
          :is="dispatchAndReturnFields[field].type"
          v-for="field in ['dispatch_phone', 'dispatch_email']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="dispatchAndReturn[field]"
          :is-editable="true"
          class="h-auto"
          v-bind="getProps(field, dispatchAndReturnFields)"
        />
        <Transition name="fade">
          <div v-if="isReturnContactIsDifferent">
            <div
              class="d-flex align-items-center"
              style="gap: 8px"
            >
              <component
                :is="dispatchAndReturnFields['return_contact_id'].type"
                ref="dispatch_contact_id_ref"
                v-model="dispatchAndReturn['return_contact_id']"
                class="w-100"
                v-bind="getProps('return_contact_id', dispatchAndReturnFields, true, 'return_contact_id', getCustomerId)"
                @input="onDispatchReturnContactChange($event, 'return_contact_id')"
              />
              <button-dashed
                class="btnBorderRevert position-relative"
                style="top: 6px"
              >
                <feather-icon
                  icon="LAddButtonIcon"
                  class="cursor-pointer"
                  size="32"
                  @click="gotoNewPage(`/customers/${orderInformationFormCustomerID}/contacts`, $event, '_blank')"
                />
              </button-dashed>
            </div>
            <component
              :is="dispatchAndReturnFields[field].type"
              v-for="field in ['return_phone', 'return_email']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="dispatchAndReturn[field]"
              :is-editable="true"
              class="h-auto"
              v-bind="getProps(field, dispatchAndReturnFields)"
            />
          </div>
        </Transition>

        <div
          v-for="field in ['dispatch_address_id']"
          :key="field"
          class="d-flex align-items-center"
          style="gap: 8px"
        >
          <component
            :is="dispatchAndReturnFields[field].type"
            :ref="`${field}_ref`"
            v-model="dispatchAndReturn[field]"
            :is-editable="!Boolean(dispatchAndReturnAddress)"
            class="h-auto w-100"
            v-bind="getProps(field, dispatchAndReturnFields, true, field, getCustomerId)"
          />
          <button-dashed
            class="btnBorderRevert position-relative"
            style="top: 6px"
          >
            <feather-icon
              icon="LAddButtonIcon"
              class="cursor-pointer"
              size="32"
              @click="gotoNewPage(`/customers/${orderInformationFormCustomerID}/addresses`, $event, '_blank')"
            />
          </button-dashed>
        </div>
        <div
          v-for="field in ['return_address_id']"
          :key="field"
          class="d-flex align-items-center"
          style="gap: 8px"
        >
          <component
            :is="dispatchAndReturnFields[field].type"

            :ref="`${field}_ref`"
            v-model="dispatchAndReturn[field]"
            :is-editable="!Boolean(dispatchAndReturnAddress)"
            class="h-auto w-100"
            v-bind="getProps(field, dispatchAndReturnFields, true, field, getCustomerId)"
          />
          <button-dashed
            class="btnBorderRevert position-relative"
            style="top: 6px"
          >
            <feather-icon
              icon="LAddButtonIcon"
              class="cursor-pointer"
              size="32"
              @click="gotoNewPage(`/customers/${orderInformationFormCustomerID}/addresses`, $event, '_blank')"
            />
          </button-dashed>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { getProps, getValueFromGivenObjectByKey } from '@core/utils/utils'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import config from '../config'

export default {
  name: 'DispatchAndReturn',
  components: {
    BRow,
    BCol,
    ButtonDashed,
  },
  computed: {
    dispatchAndReturn() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm
    },
    dispatchAndReturnAddress() {
      return this.$store.state.listModule.dispatchAndReturnAddress.length
    },
    orderInformationFormCustomerID() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm.customer_id
    },
    isOrderReturnsToAnotherWarehouse() {
      return this.dispatchAndReturn.order_routing_order_returns_to_another_warehouse
    },
    isReturnContactIsDifferent() {
      return this.dispatchAndReturn.return_contact_is_different
    },
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    orderInformation() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm
    },
    currentWarehouse() {
      return this.$store.state.app.warehouse
    },
    dispatchList() {
      return this.$store.state.listModule.dispatchAndReturnMethodsList
    },
  },
  watch: {
    dispatchAndReturn(obj) {
      this.checkIsAllRequiredFieldsFilled(obj, this.dispatchAndReturnRequiredFields, this, 'SET_IS_DISPATCH_AND_RETURN_ALL_REQUIRED_FIELDS_FILLED')
    },
    dispatchList(obj) {
      if (this.filteredDispatches(obj)) {
        const data = this.filteredDispatches(obj)
        this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {
          ...this.dispatchAndReturn,
          dispatch_method: data,
        })
      }
    },
    currentWarehouse(obj) {
      if (this.dispatchAndReturn?.dispatch_warehouse_id) return
      this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {
        ...this.dispatchAndReturn,
        dispatch_warehouse_id: {
          id: obj.id,
          label: obj.label,
        },
      })
    },
  },
  methods: {
    filteredDispatches(obj) {
      const data = obj.filter(item => item.is_default)
      return data[0]
    },
    onDispatchMethodChange(method, field) {
      if (this.DISPATCH_METHOD_FIELD_NAME === field) {
        console.log(method)
      }
    },
    onDispatchReturnContactChange(contact, field) {
      if (field === this.RETURN_CONTACT_FIELD_NAME) {
        this.dispatchAndReturn.return_phone = getValueFromGivenObjectByKey(contact, 'phone_one')
        this.dispatchAndReturn.return_email = getValueFromGivenObjectByKey(contact, 'email')
      }
      if (field === this.DISPATCH_CONTACT_FIELD_NAME) {
        this.dispatchAndReturn.dispatch_phone = getValueFromGivenObjectByKey(contact, 'phone_one')
        this.dispatchAndReturn.dispatch_email = getValueFromGivenObjectByKey(contact, 'email')
      }
    },
    getParentValue() {
      if (this.dispatchAndReturn.dispatch_method?.linked_supplier_account_id) {
        return this.dispatchAndReturn.dispatch_method?.linked_supplier_account_id
      }
      return null
    },
    getCustomerId() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm.customer_id
    },
  },
  setup() {
    const {
      MODULE_NAME,
      dispatchAndReturnFields,
      checkIsAllRequiredFieldsFilled,
      DISPATCH_FORM_TITLE,
      RETURN_FORM_TITLE,
      dispatchAndReturnRequiredFields,
    } = config()
    const isEditable = ref(true)

    const DISPATCH_METHOD_FIELD_NAME = 'dispatch_method'
    const RETURN_CONTACT_FIELD_NAME = 'return_contact_id'
    const DISPATCH_CONTACT_FIELD_NAME = 'dispatch_contact_id'

    return {
      getProps,
      isEditable,
      MODULE_NAME,
      dispatchAndReturnFields,
      DISPATCH_METHOD_FIELD_NAME,
      RETURN_CONTACT_FIELD_NAME,
      DISPATCH_CONTACT_FIELD_NAME,
      checkIsAllRequiredFieldsFilled,
      DISPATCH_FORM_TITLE,
      RETURN_FORM_TITLE,
      dispatchAndReturnRequiredFields,
    }
  },
}
</script>

<style>
.order-routing-title{
  padding-left: 10px;
}
</style>
