var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-wrapper"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('h3',{staticClass:"font-weight-bolder mb-0 d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.$t(_vm.OTHER_CHARGES_FORM_TITLE))+" ")]),_c('autosuggest',_vm._b({ref:"autocomplete",staticClass:"ml-1 flex-grow-1",attrs:{"suggestions":_vm.suggestions,"input-props":_vm.getSearchInputProps(),"table-suggestion":true,"table-suggestion-configs":_vm.otherChanesTableAutoSuggestTableColumns},on:{"input":_vm.fetchResults,"selected":_vm.selectHandler},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}},'autosuggest',_vm.getSuggestProps(),false))],1)])],1),_c('l-draggable-table-list-collector',{ref:"lTableRef",staticClass:"rental-sales-table",attrs:{"has-footer":true,"is-searchable":false,"module-name":_vm.MODULE_NAME,"fetched-data":_vm.fetchedRentals(),"table-columns":_vm.tableColumnsOtherChanes,"is-dragan-able":false,"table-suggestion-configs":_vm.otherChanesTableAutoSuggestTableColumns,"has-below-actions":true,"table-config-options":{
      endpoint: (_vm.MODULE_NAME_CATALOG + "/nonStocksActiveList")
    }},on:{"getCollectedList":_vm.getCollectedList,"rowHoveredHandler":_vm.rowHoveredHandler,"rowUnHoveredHandler":_vm.rowUnHoveredHandler},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(ref){
    var data = ref.data;
return [(data.id)?_c('div',{staticClass:"d-flex p-0"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.click.bottom",modifiers:{"noninteractive":true,"click":true,"bottom":true}}],staticClass:"lightIcon cursor-pointer border-dotted featherIcon",staticStyle:{"padding":"4px"},attrs:{"icon":"LTrashIcon","title":_vm.$t('Remove'),"size":"30"},on:{"click":function($event){return _vm.remove({id: data.id})}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.click.bottom",modifiers:{"noninteractive":true,"click":true,"bottom":true}}],staticClass:"lightIcon cursor-pointer border-dotted featherIcon",staticStyle:{"padding":"4px"},attrs:{"icon":"LEyeIcon","size":"30","title":_vm.$t('Watch')},on:{"click":function($event){return _vm.navigateToProduct(data, $event)}}})],1):_vm._e()]}},{key:"cell(sku)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.sku ? data.sku : '-')+" ")])]}},{key:"cell(name)",fn:function(ref){
    var data = ref.data;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"cell-row-name",attrs:{"title":data.name}},[_vm._v(" "+_vm._s(data.name)+" ")])]}},{key:"cell(units)",fn:function(ref){
    var data = ref.data;
return [(data.id)?_c('increment-decrement-input',{ref:"incrementDecrementInputItemsRental",staticClass:"d-flex justify-content-center",staticStyle:{"height":"25px","width":"60px","margin":"auto"},attrs:{"item":data,"item-key-name-for-amount":"amount","on-index-comparison":data.id === _vm.getTableRowIndex,"is-disabled-minus":data.amount === 1},on:{"onIncrementDecrementAction":function($event){return _vm.updateProductItemPrice()}},scopedSlots:_vm._u([{key:"switch-off-action",fn:function(){return [_c('div',{staticClass:"switch-off-action"},[_vm._v(" "+_vm._s(data.amount ? data.amount : 1)+" ")])]},proxy:true}],null,true)}):_c('div',{staticStyle:{"margin-left":"47%"}},[_vm._v(" 1 ")])]}},{key:"cell(rates)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(data.price))+" ")])]}},{key:"cell(discount)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(data.discountPrice))+" ")])]}},{key:"cell(subtotal)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(data.subtotal))+" ")])]}},{key:"cell(taxes)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(data.tax))+" ")])]}},{key:"cell(total)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(data.totalPrice))+" ")])]}},{key:"foot(name)",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$t('TOTAL'))+" ")])]},proxy:true},{key:"foot(units)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getItemKVOfProduct({ productKey: "amount" }))+" ")])]},proxy:true},{key:"foot(price)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.getItemKVOfProduct({ productKey: "price" })))+" ")])]},proxy:true},{key:"foot(discount)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.getItemKVOfProduct({ productKey: "discountPrice" })))+" ")])]},proxy:true},{key:"foot(subtotal)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.getItemKVOfProduct({ productKey: "subtotal" })))+" ")])]},proxy:true},{key:"foot(taxes)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.getItemKVOfProduct({ productKey: "tax" })))+" ")])]},proxy:true},{key:"foot(total)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.getItemKVOfProduct({ productKey: "totalPrice" })))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }