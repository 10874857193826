<template>
  <div class="pb-2">
    <portal to="tab-title">
      <div>
        <h4 class="text-center font-weight-bolder">
          {{ $t('Catalog') }}
        </h4>
      </div>
    </portal>
    <checkbox-tabs
      :is-disabled="this.$refs.lTableRef ? this.$refs.lTableRef.isBusy : false"
      @queryChanged="fetchTableData"
    />
    <l-table
      ref="lTableRef"
      :table-config-options="getTableConfigOptions()"
      :module-name="MODULE_NAME"
      :table-columns="tableStockColumns"
      :is-delete="true"
      :filter-class="new Products()"
      :has-action="true"
      :create-page-url="{ name: 'home-catalog-create' }"
      :create-btn-dashed="true"
      :is-suggestion-table="true"
      trash-list-path="home-main-catalog-trash-list-stocks"
      :can-create="ACCESS_ABILITY_CATALOG"
      :can-show-trash-list="ACCESS_ABILITY_CATALOG"
      action-name="changeStatus"
      :query-params="getQueryParams()"
      :confirm-notification-options="getConfirmNotificationOptions()"
    >
      <!--      row-clicked="home-catalog-view"-->
      <template #cell(name)="{ data }">
        <div
          v-b-tooltip.noninteractive.hover
          class="name-cell cursor-pointer"
          :title="getValue(data, 'name') ? getValue(data, 'name') : '—'"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover
            icon="LKitsIcon"
            title="View the kit"
            :style="{ display: checkForCat(data.item.type) ? 'none' : '' }"
            size="26"
            @click="goToPage(data.item)"
          />
          <feather-icon
            icon="LNonStockIcon"
            size="26"
            :style="{ display: checkForCat(data.item.type) ? '' : 'none' }"
            class="mr-1"
          />
          <span @click="goToPage(data.item)">
            {{ getValue(data, 'name') ? getValue(data, 'name') : '—' }}
          </span>
        </div>
      </template>
      <template #cell(sku)="{ data }">
        <div
          class="cursor-pointer"
          @click="goToPage(data.item)"
        >
          {{ data.item.sku }}
        </div>
      </template>
      <template #cell(icon)="{ data }">
        <feather-icon
          :icon="renderIcon(data.item)"
          size="26"
          class="cursor-pointer defaultIconColor"
        />
      </template>
      <template #cell(is_active)="{ data }">
        {{ data.item.is_active === null ? '—' : data.item.is_active ? 'Active' : 'Inactive' }}
      </template>
      <template #cell(calendarIcon)="{ data }">
        <feather-icon
          v-b-tooltip.noninteractive.hover
          icon="LStockCalendarIcon"
          title="Calendar"
          :class="{ 'disabled-icon': !checkForCat(data.item.type) }"
          size="32"
          :style="{ cursor: checkForCat(data.item.type) ? 'pointer' : 'not-allowed' }"
          @click="!checkForCat(data.item.type) ? null : navigateToCalendar(data.item.id, $event)"
        />
        <feather-icon
          v-b-tooltip.noninteractive.hover.bottom
          icon="LStockEyeIcon"
          class="ml-1 cursor-pointer"
          size="32"
          :title="$t('View')"
          @click="goToPage(data.item)"
        />
      </template>
      <template #table-top-right-side-extras-prev="{ data }">
        <actions
          icon-name="LExportIcon"
          title="Export"
          :b-drop-down-items="exportButtonItems"
          @actionHandler="exportActions"
        />
        <actions
          icon-name="LImportIcon"
          title="Import"
          :b-drop-down-items="exportButtonItems"
          @actionHandler="importActions"
        />
      </template>
    </l-table>
    <export-c-s-v
      ref="export-c-s-v"
      :module-name="MODULE_NAME"
    />
    <import-c-s-v
      ref="import-c-s-v"
      :module-name="MODULE_NAME"
      @refetchTable="refetchData"
    />
  </div>
</template>

<script>
import ListComponent from '@/views/main/catalog/components/ListComponent.vue'
import { VBTooltip } from 'bootstrap-vue'
import Products from '@/views/components/filters/fields/Products'
import config from '@/views/main/catalog/productsConfig'
import ImportCSV from '@/views/components/Modals/ImportCSV.vue'
import ExportCSV from '@/views/components/Modals/ExportCSV.vue'
import LTable from '@/views/components/LTable/LTable.vue'
import Actions from '@/views/components/Actions.vue'
import CheckboxTabs from '@/views/main/catalog/components/CheckboxTabs.vue'
import { CATALOG_TYPE_CONTAINER } from '@/views/main/catalog/catalogType'

export default {
  name: 'ContainersList',
  components: {
    CheckboxTabs,
    Actions,
    LTable,
    ExportCSV,
    ImportCSV,
    ListComponent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    Products() {
      return Products
    },
    exportButtonItems() {
      return this.$store.state[this.MODULE_NAME].exportButtonItems
    },
  },
  created() {
    // Initialization code
  },
  methods: {
    goToPage(item) {
      this.$router.push({
        name: this.checkForCat(item.type) ? 'home-catalog-view' : 'home-kits-view',
        params: { id: item.id },
      })
    },
    checkForCat(type) {
      return type !== 'kits'
    },
    getQueryParams() {
      // Return query parameters based on the component state
      return {
        is_active: false,
        ...this.$route.query,
      }
    },
    navigateToCalendar(id, event) {
      this.navigateTo({ id }, event, 'home-inventory-calendar')
    },
    navigateTo(params, event, name) {
      event.preventDefault()
      if (event.ctrlKey) {
        const route = this.$router.resolve({
          name,
          params,
        })
        window.open(route.href, '_blank')
      } else {
        this.$router.push({
          name,
          params,
        })
          .catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err
            }
          })
      }
    },
    getConfirmNotificationOptions() {
      return {
        text: 'It is possible to revert this',
        confirmButtonText: 'Yes, deactivate it',
      }
    },
    refetchData() {
      console.log('Fetching data with params:', this.getQueryParams())
      this.$refs.lTableRef.refetchData()
    },
    renderIcon(data) {
      return data
    },
    getValue(data, tableColumnName) {
      return data.item[tableColumnName] ?? {}
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/products`,
      }
    },
    exportActions({
      btnTitle,
      actionType,
    }) {
      this.$store.commit(`${this.MODULE_NAME}/SET_EXPORT`, {
        title: btnTitle,
        actionType,
        query: this.$refs.lTableRef.totalQuery,
      })
      this.$refs['export-c-s-v'].show()
    },
    importActions({
      btnTitle,
      actionType,
    }) {
      this.$store.commit(`${this.MODULE_NAME}/SET_IMPORT`, {
        title: btnTitle,
        actionType,
        query: this.$refs.lTableRef.totalQuery,
      })
      this.$refs['import-c-s-v'].show()
    },

    async fetchTableData(callback) {
      this.isDisabled = true
      try {
        await this.$refs.lTableRef.refetchData()
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.isDisabled = false
        if (typeof callback === 'function') {
          callback()
        }
      }
    },
  },
  setup() {
    const MODULE_NAME = 'catalogs'
    const {
      tableStockColumns,
      ACCESS_ABILITY_CATALOG,
    } = config()
    return {
      MODULE_NAME,
      tableStockColumns,
      ACCESS_ABILITY_CATALOG,
    }
  },
}
</script>
<style scoped>
.disabled-icon {
  opacity: 0.5;
  pointer-events: none;
}
</style>
