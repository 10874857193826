<template>
  <div class="form-wrapper">
    <b-row>
      <b-col cols="6">
        <div class="d-flex align-center">
          <h4 class="font-weight-bolder mb-0 d-flex align-items-center">
            {{ $t(ORDER_ITEMS_SALES_TABLE_TITLE) }}
          </h4>
          <autosuggest
            ref="autocomplete"
            v-model="searchQuery"
            class="w-100 ml-1 mr-1"
            :suggestions="suggestions"
            :input-props="getSearchInputProps()"
            v-bind="getSuggestProps()"
            :table-suggestion="true"
            :is-editable="!this.orderInformationForm.customer_id"
            :table-suggestion-configs="saleTableAutoSuggestTableColumns"
            @input="fetchResults"
            @selected="selectHandler"
          />
        </div>
      </b-col>
      <!--      <b-col>-->
      <!--        <b-form-checkbox-->
      <!--          v-model="allowToPickRentalTimesByLineItem"-->
      <!--          @change="onAllowToPickRentalTimesByLineItem($event)"-->
      <!--        >-->
      <!--          {{ $t('Allow to pick rental times by line item ') }}-->
      <!--        </b-form-checkbox>-->
      <!--      </b-col>-->
    </b-row>
    <!--    :additional-object-for-selected-item="{-->
    <!--    note: '',-->
    <!--    isNoteOpen: false,-->
    <!--    rental_start: eventInformationRentalStart,-->
    <!--    rental_end: eventInformationRentalEnd,-->
    <!--    inheritFromOrder: true,-->
    <!--    event_information_rental_start_dispatch_time:-->
    <!--    eventInformationRentalStartDispatchTime,-->
    <!--    event_information_rental_start_end_time: eventInformationRentalEndTime,-->
    <!--    }"-->
    <l-draggable-table-list-collector
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :fetched-data="fetchedSales()"
      :table-columns="tableColumnsSales"
      :table-suggestion-configs="saleTableAutoSuggestTableColumns"
      :is-searchable="false"
      :is-busy="false"
      :has-below-actions="true"
      :has-footer="true"
      :is-dragan-able="false"
      class="rental-sales-table"
      autosuggest-list-item-name="name"
      collection-of-list-item-i-d="id"
      @rowHoveredHandler="rowHoveredHandler"
      @rowUnHoveredHandler="rowUnHoveredHandler"
      @getCollectedList="getCollectedList"
    >
      <template #cell(actions)="{data}">
        <div
          :id="`td-${data.id}`"
          class="d-flex p-0"
          style="gap: 8px"
        >

          <feather-icon
            v-b-tooltip.noninteractive.click.bottom
            icon="LTrashIcon"
            class="lightIcon cursor-pointer border-dotted featherIcon"
            :title="$t('Remove')"
            size="30"
            style="padding: 4px"
            @click="remove(data)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.click.bottom
            icon="LEyeIcon"
            size="30"
            style="padding: 4px"
            class="lightIcon cursor-pointer border-dotted featherIcon"
            :title="$t('Watch')"
            @click="navigateToProduct(data, $event)"
          />
        </div>
      </template>
      <template #cell(sku)="{ data }">
        <span
          :id="`${'sale-sku' + data.id}`"
          style="cursor: pointer;"
          @click="showTooltip(data)"
        >
          {{ data.sku }}
        </span>
        <l-tool-tip
          :ref="`${'SaleLToolTip' + data.id}`"
          placement="bottom"
          :target="`${'sale-sku' + data.id}`"
        >
          <div class="d-flex flex-column">
            <div class="w-100 d-flex justify-content-end">
              <feather-icon
                icon="XIcon"
                class="lightIcon cursor-pointer border-dotted featherIcon"
                size="16"
                @click="closeTooltip(data)"
              />
            </div>
            <div class="w-100 d-flex justify-content-between">
              <table class="w-100 mt-1">
                <tr>
                  <th />
                  <th />

                  <th>Avail.</th>
                  <th>Other WHs</th>

                </tr>
                <tr
                  v-for="(calendarState, index) in calendarStates.warehouse"
                  :key="index"
                >
                  <td class="text-left">
                    {{ calendarState.date }}
                  </td>
                  <td>
                    <div style="width: 20px; height: 1px;" />
                  </td>
                  <td>{{ calendarState.current_warehouse_count }}</td>
                  <td>{{ calendarState.other_warehouses_count }}</td>
                </tr>
              </table>
            </div>
            <div
              class="d-flex mt-2"
              style="text-decoration: underline;"
            >
              {{ $t('Alternatives') }}
            </div>
            <div
              v-for="(alternative, index) in calendarStates.alternatives"
              :key="index"
              class="d-flex justify-content-between"
            >
              <div class="d-flex justify-content-start">
                {{ alternative.name }}
              </div>
              <div class="d-flex">
                <feather-icon
                  icon="PlusIcon"
                  class="lightIcon cursor-pointer border-dotted featherIcon"
                  size="16"
                  @click="addAlternative(alternative, data)"
                />
                <feather-icon
                  icon="LReloadIcon"
                  class="lightIcon cursor-pointer border-dotted featherIcon"
                  size="16"
                  @click="reloadAlternative(alternative, data)"
                />
              </div>
            </div>

          </div>
        </l-tool-tip>
      </template>
      <template #cell(name)="{ data }">
        <div
          v-b-tooltip.noninteractive.hover
          class="cell-row-name"
          :title="data.name"
        >
          {{ data.name }}
        </div>
      </template>
      <template #cell(units)="{ data }">
        <increment-decrement-input
          ref="incrementDecrementInputItemsSale"
          class="d-flex justify-content-center"
          :item="data"
          style="height: 25px; width: 60px; margin: auto"
          item-key-name-for-amount="amount"
          :is-disabled-minus="data.amount === 1"
          :on-index-comparison="data.id === getTableRowIndex"
          @onIncrementDecrementAction="updateProductItemPrice"
        >
          <template #switch-off-action>
            <div class="switch-off-action">
              {{ data.amount ? data.amount : 1 }}
            </div>
          </template>
        </increment-decrement-input>
      </template>
      <template #cell(price)="{ data }">
        <span style="display: block; text-align: center">
          {{ formatNumberToCurrencyView(data.price) }}
        </span>
      </template>
      <template #cell(discount)="{data}">
        <span style=" display: block; text-align: center">
          {{ data.discount }}%
        </span>
      </template>
      <template #cell(subtotal)="{data}">
        <span style=" display: block; text-align: center">
          {{ formatNumberToCurrencyView(data.subtotal) }}
        </span>
      </template>
      <template #cell(taxes)="{data}">
        <span style=" display: block; text-align: center">
          {{ formatNumberToCurrencyView(data.taxes) }}
        </span>
      </template>
      <template #cell(total)="{data}">
        <span style=" display: block; text-align: center">
          {{ formatNumberToCurrencyView(data.totalPrice) }}
        </span>
      </template>

      <!--  Footer Cells    -->
      <template
        #foot(name)=""
      >
        <div>
          {{ $t('TOTAL') }}
        </div>
      </template>
      <template
        #foot(units)=""
      >
        <div class="text-center">
          {{ getItemKVOfProduct({productKey: "amount"}) }}
        </div>
      </template>

      <template
        #foot(price)=""
      >
        <div class="text-center">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({productKey: "price"})) }}
        </div>
      </template>
      <template
        #foot(discount)=""
      >
        <div class="text-center">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({productKey: "discountPrice"})) }}
        </div>
      </template>
      <template
        #foot(subtotal)=""
      >
        <div class="text-center">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({productKey: "subtotal"})) }}
        </div>
      </template>
      <template
        #foot(taxes)=""
      >
        <div class="text-center">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({productKey: "taxes"})) }}
        </div>
      </template>
      <template
        #foot(total)=""
      >
        <div class="text-center">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({productKey: "totalPrice"})) }}
        </div>
      </template>
    </l-draggable-table-list-collector>
  </div>
</template>

<script>
import { BCol, BRow, VBTooltip } from 'bootstrap-vue'
import { formatNumberToCurrencyView, getProps, sumTotalAmountOfGivenArrayByProperty } from '@core/utils/utils'
import LDraggableTableListCollector from '@/views/components/LDraggableTableListCollector.vue'
import * as configRentalSale from '@/views/main/orders/view/rental-sales/config'
import LToolTip from '@/views/components/LToolTip.vue'
import Autosuggest from '@/views/components/AutoSuggest/Autosuggest.vue'
import config from '../../../orders/view/rental-sales/components/create/config'

function debounce(func, timeout) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => func(...args), timeout)
  }
}
export default {
  name: 'SalesTable',
  components: {
    LToolTip,
    LDraggableTableListCollector,
    Autosuggest,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      getTableRowIndex: null,
      calendarStates: {},
      searchQuery: '',
      suggestions: [],
      customer_id: this.$store.state[this.MODULE_NAME].orderInformationForm.customer_id ?? 0
    }
  },
  computed: {
    orderItemsSales() {
      return this.$store.state[this.MODULE_NAME].orderItemsSalesTable
    },
    orderInformationForm() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm
    },
  },
  watch: {
    orderInformationForm(v) {
      if (!(v.customer_id === this.customer_id) && this.customer_id !== 0){
        this.clearProduct()
        this.customer_id = v.customer_id
      }
    },
  },
  methods: {
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'table-search',
        placeholder: 'Search...',
        class: 'form-control',
        name: 'table-search',
      }
      return {
        ...defaultInputProps,
      }
    },
    getSuggestProps() {
      const defaultProps = {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
      return {
        ...defaultProps,
      }
    },
    renderSuggestion(suggestion) {
      return suggestion.item.cust_name_dba
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion

      return item.cust_name_dba
    },

    fetchResults(searchQuery) {
      this.search(searchQuery, this)
      this.$emit('onInput', searchQuery)
    },

    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 50),
    getSelectOptions(params) {
      this.suggestions = []
      if (params && params.length > 2) {
        this.autoSuggestLoading = true

        this.$store.dispatch(`${this.MODULE_NAME}/saleOrders`, {
          search: params,
          f: {
            is_active: 1,
          },
        }).then(({ data }) => {
          const mappedData = data.data.data
          this.suggestions = [{ data: mappedData }]
        }).finally(() => {
          this.autoSuggestLoading = false
        })
      }
    },
    selectHandler(suggest) {
      this.$refs.lTableRef.selectHandler(suggest)
      this.suggestions = []
    },

    navigateToProduct({ id }, e) {
      this.gotoNewPage({ name: 'home-catalog-general-info', params: { id } }, e, true)
    },
    updateProductItemPrice() {
      this.calculatePriceOfProducts({
        productsList: this.orderItemsSales,
        fieldName: {},
        storeActionKey: 'SET_ORDER_ITEMS_SALES_TABLE',
      })
    },
    rowHoveredHandler(rowData) {
      if (!this.$refs.incrementDecrementInputItemsSale) return
      const { id, amount } = rowData
      this.getTableRowIndex = id
      this.$refs.incrementDecrementInputItemsSale.setValue(amount ?? 1)
    },
    rowUnHoveredHandler() {
      this.getTableRowIndex = null
    },
    remove({ id }) {
      const orderItemsSales = this.orderItemsSales.filter(item => item.id !== id)
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALES_TABLE`, orderItemsSales)
    },
    fetchedSales() {
      return this.orderItemsSales
    },
    getCollectedList(list) {
      const productsList = list.map(item => ({
        ...item,
        ...list[list.length - 1].id === item.id && {
          ...item,
          amount: item.amount ?? 1,
          taxes: 0,
          type: 'sale',
        },
      }))

      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALES_TABLE`, productsList)
      this.updateProductItemPrice()
    },
    getItemKVOfProduct({ productKey }) {
      return this.sumTotalAmountOfGivenArrayByProperty(
        this.orderItemsSales,
        productKey,
      )
    },
    showTooltip(data) {
      this.$store.dispatch(`${this.MODULE_NAME}/getCalendarState`, { id: data.id }).then(response => {
        const { data } = response.data
        this.calendarStates = data
      })
    },
    closeTooltip(data) {
      this.$refs[`SaleLToolTip${data.id}`].hide()
    },

    addAlternative(alternative, data) {
      const products = this.getNewSaleProductList(alternative, data)
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALES_TABLE`, products)
      this.$refs[`SaleLToolTip${data.id}`].hide()
    },
    reloadAlternative(alternative, data) {
      this.remove(data)
      const products = this.getNewSaleProductList(alternative, data)
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALES_TABLE`, products)
      this.$refs[`SaleLToolTip${data.id}`].hide()
    },
    getNewSaleProductList(alternative, data) {
      const product = this.orderItemsSales.find(item => item.id === alternative.id)
      if (product) {
        return this.orderItemsSales.map(item => {
          if (item.id === alternative.id) {
            return {
              ...item,
              amount: item.amount + 1,
            }
          }
          return item
        })
      }
      return [
        ...this.orderItemsRental,
        ...[{
          available: alternative.available,
          discount: alternative.discount,
          id: alternative.id,
          is_active: alternative.is_active,
          name: alternative.name,
          other_locations: alternative.other_locations,
          price: alternative.price,
          replacement_cost: alternative.replacement_cost,
          sku: alternative.sku,
          status: alternative.status,
          type: alternative.type,
          amount: data.amount,
          days: data.days,
          discountPrice: data.discountPrice,
          isNoteOpen: data.isNoteOpen,
          notes: data.notes,
          subtotal: data.subtotal,
          taxes: data.taxes,
          totalPrice: data.totalPrice,
        }],
      ]
    },
    clearProduct() {
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALES_TABLE`, [])
    },
  },
  setup() {
    const {
      tableColumnsSales,
      MODULE_NAME,
      MODULE_NAME_CATALOG,
      ORDER_ITEMS_SALES_TABLE_TITLE,
      saleTableAutoSuggestTableColumns,
      orderItemsSalesFields,
    } = config()

    const { calculatePriceOfProducts } = configRentalSale.default()

    return {
      getProps,
      MODULE_NAME,
      MODULE_NAME_CATALOG,
      ORDER_ITEMS_SALES_TABLE_TITLE,
      tableColumnsSales,
      orderItemsSalesFields,
      sumTotalAmountOfGivenArrayByProperty,
      formatNumberToCurrencyView,
      saleTableAutoSuggestTableColumns,
      calculatePriceOfProducts,
    }
  },
}
</script>
<style lang="scss">
.cell-row-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.item-note-input {
  height: 34px;
  outline: none;
  background-color: transparent;

  &:focus {
    box-shadow: none;
  }

  .rental-sales-table .l-tableList-collector__auto-suggest input {
    background-color: #fff !important;
  }

  .rental-sales-table thead th {
    padding: 5px !important;
  }

}

.rental-sales-table-start-date {
  margin-bottom: 0;
  gap: 10px;

  label {
    font-weight: bold;
  }

  .form-control {
    height: 26px !important;
  }

  .flatpickr-input--custom-style .flatpickr-input--calendar {
    top: 5px;
  }
}

.rental-start-time {
  display: flex;

  .form-control {
    height: 26px;
    padding: 0 5px !important;
    gap: 5px;
    align-items: center;
  }

  .btn {
    padding: 0 !important;
  }
}

#rental-start-time {
  padding: 0 !important;

  #rental-start-time {
    padding: 0 !important;
  }
}
</style>
