import { ref } from '@vue/composition-api'
import store from '@/store'
import Filter from './Filter'

export default class Products extends Filter {
    filterComponent = import('@/views/components/filters/ProductsFilterComponent.vue')

    static SKU = 'sku'

    static BRAND = 'brand'

    static CATEGORIES = 'categories'

    static TYPE = 'type'

    static TAGS = 'tags'

    static NAME = 'name'

    // Product rules
    static PRODUCT_RULES = 'rules'

    static CAN_BE_RENTED_INDIVIDUALLY = 'can_be_rented_individually'

    static CAN_BE_PROCURED = 'can_be_procured'

    static CAN_BE_SUB_RENTED = 'can_be_sub_rented'

    static CAN_BE_SOLD = 'can_be_sold'

    static CAN_BE_TRANSFERRED = 'can_be_transferred'

    static BARCODE = 'barcode'

    static STATUS = 'status'

    fields = {
      [Products.SKU]: {
        label: 'SKU',
      },
      [Products.BRAND]: {
        label: 'Brand',
        store: 'brandsList',
        filterBy: 'name',
      },
      [Products.CATEGORIES]: {
        label: 'Categories',
        options: {
          multiple: true,
          label: 'label',
        },
        store: 'categoriesList',
        mapping(value) {
          if (Array.isArray(value)) return value.map(item => (item.id ? item.id : item))
          return []
        },
        selectedLabel(value) {
          if (Array.isArray(value)) {
            return value.map(item => item.label).join(', ')
          }
          return value
        },
      },
      [Products.TYPE]: {
        label: 'Type',
        store: 'productTypesList',
      },
      [Products.TAGS]: {
        label: 'Tags',
        store: 'tagsList',
        options: {
          multiple: true,
          label: 'label',
        },
        mapping(value) {
          if (Array.isArray(value)) return value.map(item => (item.id ? item.id : item))
          return []
        },
        selectedLabel(value) {
          if (Array.isArray(value)) {
            return value.map(item => item.label).join(', ')
          }
          return value
        },
      },
      [Products.NAME]: {
        label: 'Product Name',
      },
      [Products.BARCODE]: {
        label: 'Barcode',
      },
      [Products.PRODUCT_RULES]: {
        [Products.CAN_BE_RENTED_INDIVIDUALLY]: {
          label: 'Can be rented individually',
          selectedValue: this.selectedOnlyLabel,
        },
        [Products.CAN_BE_PROCURED]: {
          label: 'Can be procured',
          selectedValue: this.selectedOnlyLabel,
        },
        [Products.CAN_BE_SUB_RENTED]: {
          label: 'Can be sub rented',
          selectedValue: this.selectedOnlyLabel,
        },
        [Products.CAN_BE_SOLD]: {
          label: 'Can be sold',
          selectedValue: this.selectedOnlyLabel,
        },
        [Products.CAN_BE_TRANSFERRED]: {
          label: 'Can be transferred',
          selectedValue: this.selectedOnlyLabel,
        },
      },
      [Products.STATUS]: {
        label: 'Status',
        store: 'status01List',
        firstSelect: true,
        responseFilter(data) {
          return data !== null ? store.getters['listModule/getStatusO1'](data) : { id: 1, name: 'Active',}
        },
      },
    }

    selectedFilters = ref()

    // eslint-disable-next-line class-methods-use-this
    getResponseFilter(response) {
      return response.data.meta.filters ?? {}
    }

    selectedOnlyLabel(label, value) {
      if (value) return super.selectedOnlyLabel(label)
      return null
    }
}
